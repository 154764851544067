import React, { ReactNode } from 'react'

export default function SocialMedia() {
    return (
        <ul className="flex flex-wrap items-center justify-center">
            <Item url="https://twitter.com/_akzhy" title="Twitter">
                <Twitter />
            </Item>
            <Item url="https://codepen.io/akzhy/" title="Codepen">
                <Codepen />
            </Item>
            <Item
                url="https://stackoverflow.com/users/3971928/akshay"
                title="StackOverflow"
            >
                <StackOverflow />
            </Item>
            <Item url="https://github.com/akzhy" title="GitHub">
                <GitHub />
            </Item>
        </ul>
    )
}

const Item = ({
    children,
    url,
    title,
}: {
    children: ReactNode
    url: string
    title: string
}) => (
    <li>
        <a
            className="w-32 h-32 p-4 border-2 border-transparent focus:border-primary focus:outline-none mx-4 relative block"
            href={url}
            title={title}
        >
            {children}
        </a>
    </li>
)

const Twitter = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 400 400"
        xmlSpace="preserve"
    >
        <g id="twitter_main">
            <g>
                <circle fill="#1DA1F2" cx="200" cy="200" r="200" />
            </g>
            <g>
                <path
                    fill="#FFFFFF"
                    d="M163.4,305.5c88.7,0,137.2-73.5,137.2-137.2c0-2.1,0-4.2-0.1-6.2c9.4-6.8,17.6-15.3,24.1-25   c-8.6,3.8-17.9,6.4-27.7,7.6c10-6,17.6-15.4,21.2-26.7c-9.3,5.5-19.6,9.5-30.6,11.7c-8.8-9.4-21.3-15.2-35.2-15.2   c-26.6,0-48.2,21.6-48.2,48.2c0,3.8,0.4,7.5,1.3,11c-40.1-2-75.6-21.2-99.4-50.4c-4.1,7.1-6.5,15.4-6.5,24.2   c0,16.7,8.5,31.5,21.5,40.1c-7.9-0.2-15.3-2.4-21.8-6c0,0.2,0,0.4,0,0.6c0,23.4,16.6,42.8,38.7,47.3c-4,1.1-8.3,1.7-12.7,1.7   c-3.1,0-6.1-0.3-9.1-0.9c6.1,19.2,23.9,33.1,45,33.5c-16.5,12.9-37.3,20.6-59.9,20.6c-3.9,0-7.7-0.2-11.5-0.7   C110.8,297.5,136.2,305.5,163.4,305.5"
                />
            </g>
        </g>
    </svg>
)

const Codepen = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 122 120">
        <style>
            {`.theme-dark .cplogo {
                        fill: #fff;
                    }
            `}
        </style>
        <g>
            <path
                d="M60,0C26.863,0,0,26.863,0,60c0,33.138,26.863,60,60,60c33.137,0,60-26.861,60-60C120,26.863,93.138,0,60,0z M60,110.146
                C32.35,110.146,9.854,87.65,9.854,60S32.35,9.854,60,9.854c27.65,0,50.145,22.496,50.145,50.146S87.65,110.146,60,110.146z"
                className="cplogo"
            />
            <path
                className="cplogo"
                d="M97.071,48.281c-0.007-0.047-0.019-0.092-0.026-0.139c-0.016-0.09-0.032-0.18-0.056-0.267
                c-0.014-0.053-0.033-0.104-0.05-0.154c-0.025-0.078-0.051-0.156-0.082-0.232c-0.021-0.053-0.047-0.105-0.071-0.156
                c-0.033-0.072-0.068-0.142-0.108-0.21c-0.029-0.051-0.061-0.1-0.091-0.148c-0.043-0.066-0.087-0.131-0.135-0.193
                c-0.035-0.047-0.072-0.093-0.109-0.138c-0.051-0.059-0.104-0.117-0.159-0.172c-0.042-0.043-0.083-0.086-0.127-0.125
                c-0.059-0.053-0.119-0.104-0.18-0.152c-0.048-0.037-0.095-0.074-0.145-0.109c-0.019-0.012-0.035-0.027-0.053-0.039L61.769,23.438
                c-1.071-0.714-2.466-0.714-3.537,0L24.321,46.045c-0.018,0.012-0.034,0.027-0.053,0.039c-0.05,0.035-0.097,0.072-0.144,0.109
                c-0.062,0.049-0.123,0.1-0.181,0.152c-0.045,0.039-0.086,0.082-0.128,0.125c-0.055,0.055-0.108,0.113-0.158,0.172
                c-0.038,0.045-0.075,0.091-0.11,0.138c-0.047,0.062-0.092,0.127-0.134,0.193c-0.032,0.049-0.062,0.098-0.092,0.148
                c-0.039,0.068-0.074,0.139-0.108,0.21c-0.024,0.051-0.049,0.104-0.071,0.156c-0.031,0.076-0.057,0.154-0.082,0.232
                c-0.017,0.051-0.035,0.102-0.05,0.154c-0.023,0.087-0.039,0.177-0.056,0.267c-0.008,0.047-0.02,0.092-0.025,0.139
                c-0.019,0.137-0.029,0.275-0.029,0.416v22.607c0,0.141,0.011,0.279,0.029,0.418c0.006,0.045,0.018,0.092,0.025,0.137
                c0.017,0.09,0.032,0.18,0.056,0.268c0.015,0.053,0.033,0.104,0.05,0.154c0.025,0.078,0.051,0.155,0.082,0.233
                c0.021,0.053,0.047,0.104,0.071,0.154c0.034,0.072,0.069,0.143,0.108,0.213c0.029,0.049,0.06,0.098,0.092,0.146
                c0.042,0.066,0.087,0.131,0.134,0.193c0.035,0.049,0.072,0.094,0.11,0.139c0.05,0.059,0.103,0.117,0.158,0.172
                c0.042,0.043,0.083,0.086,0.128,0.124c0.058,0.053,0.118,0.104,0.181,0.152c0.047,0.037,0.094,0.074,0.144,0.109
                c0.019,0.012,0.035,0.027,0.053,0.039l33.911,22.607c0.536,0.357,1.152,0.537,1.769,0.537c0.616,0,1.233-0.18,1.768-0.537
                L95.68,73.956c0.018-0.012,0.034-0.027,0.053-0.039c0.05-0.035,0.097-0.072,0.145-0.109c0.061-0.049,0.121-0.1,0.18-0.152
                c0.044-0.038,0.085-0.081,0.127-0.124c0.056-0.055,0.108-0.113,0.159-0.172c0.037-0.045,0.074-0.09,0.109-0.139
                c0.048-0.062,0.092-0.127,0.135-0.193c0.03-0.049,0.062-0.098,0.091-0.146c0.04-0.07,0.075-0.141,0.108-0.213
                c0.024-0.051,0.05-0.102,0.071-0.154c0.031-0.078,0.057-0.155,0.082-0.233c0.017-0.051,0.036-0.102,0.05-0.154
                c0.023-0.088,0.04-0.178,0.056-0.268c0.008-0.045,0.02-0.092,0.026-0.137c0.018-0.139,0.028-0.277,0.028-0.418V48.697
                C97.1,48.557,97.089,48.418,97.071,48.281z M63.188,32.048L88.17,48.701l-11.158,7.465l-13.823-9.247V32.048z M56.812,32.048
                v14.871l-13.822,9.247l-11.159-7.465L56.812,32.048z M29.278,54.665L37.255,60l-7.977,5.335V54.665z M56.812,87.953L31.831,71.3
                l11.159-7.463l13.822,9.245V87.953z M60,67.543L48.723,60L60,52.458L71.276,60L60,67.543z M63.188,87.953V73.082l13.823-9.245
                L88.17,71.3L63.188,87.953z M90.723,65.336L82.746,60l7.977-5.335V65.336z"
            />
        </g>
    </svg>
)

const StackOverflow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
        <path fill="#bcbbbb" d="M84.4 93.8V70.6h7.7v30.9H22.6V70.6h7.7v23.2z" />
        <path
            fill="#f48023"
            d="M38.8 68.4l37.8 7.9 1.6-7.6-37.8-7.9-1.6 7.6zm5-18l35 16.3 3.2-7-35-16.4-3.2 7.1zm9.7-17.2l29.7 24.7 4.9-5.9-29.7-24.7-4.9 5.9zm19.2-18.3l-6.2 4.6 23 31 6.2-4.6-23-31zM38 86h38.6v-7.7H38V86z"
        />
    </svg>
)

const GitHub = () => (
    <svg
        role="img"
        className="github-logo"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <style>
            {`.theme-dark .github-logo path {
                    fill: #fff;
                }
            `}
        </style>
        <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
    </svg>
)
